/*
Added this to define global classes here
*/

.mb-0 {
  margin-bottom: 0;
}

.dashed-bottom-border {
  border-bottom: 1px dashed;
}

.w-7 {
  width: 7rem;
}

.mb-0-dot-5 {
  margin-bottom: 0.5rem;
}

//custom styles after antd upgrade
.ant-btn-primary {
  background-color: @cboPrimaryColor;
  border-radius: 0.25rem;
  border: none;
  height: 3rem;
  font-size: 15px;
  padding: 7.2px 15px;
  min-width: 7.5rem;
  &.ant-btn-variant-solid:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: @primaryButtonColor;
  }
  &.ant-btn-color-primary {
    box-shadow: none;
  }
}
.ant-btn-variant-link {
  color: @primaryColor;
  &.ant-btn-variant-link:not(:disabled):not(.ant-btn-disabled):hover {
    color: @cboPrimaryColor;
  }
}
.ant-btn-variant-outlined.ant-btn-color-default:not(:disabled):not(.ant-btn-disabled),
.ant-btn-variant-dashed:not(:disabled):not(.ant-btn-disabled) {
  &:hover,
  &:focus {
    color: @cboPrimaryColor;
    border-color: @cboPrimaryColor;
    background: @cboPrimaryColor;
  }
}

//Toaster related styles
.ant-notification .ant-notification-notice-wrapper .ant-notification-notice {
  padding: 0.75rem 0rem 1.25rem 1rem;
  .ant-notification-notice-icon-success.anticon {
    color: @lightThemeCBOGreen;
  }
}
.ant-notification-top.ant-notification-stack > .ant-notification-notice-wrapper {
  top: -16px;
}

.ant-notification .ant-notification-notice-wrapper .ant-notification-notice-close {
  top: 20px;
  &:hover {
    background: none;
  }
}
.ant-spin {
  .ant-spin-dot-holder {
    color: @primaryColor;
  }
  .ant-spin-text {
    color: @primaryColor;
  }
}

//radio button styles

.ant-radio-wrapper {
  &:hover {
    .ant-radio-inner {
      border-color: @primaryButtonColor;
    }
  }
  .ant-radio-checked .ant-radio-inner {
    background-color: @white;
    border: 1px solid @primaryButtonColor;
    &:after {
      background-color: @primaryButtonColor;
      transform: scale(0.42857142857142855);
    }
  }
}

//Tabs Styles
.ant-tabs {
  .ant-tabs-tab {
    .ant-tabs-tab-btn {
      &:active {
        color: @cboPrimaryColor;
      }
    }
    &.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: @cboPrimaryColor;
      &:focus-visible {
        outline: none;
        outline-offset: 0;
        transform: none;
      }
    }
    &:not(.ant-tabs-tab-disabled):hover {
      color: @cboPrimaryColor;
    }
  }
  .ant-tabs-nav {
    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        .ant-tabs-ink-bar {
          border-bottom: 2px solid @cboPrimaryColor;
        }
      }
    }
  }
}

//Switch styles
.ant-switch {
  background-color: @white;
  border: 1px solid @inputTextFieldBorderColor;
  height: 0.7rem;

  .ant-switch-handle {
    position: absolute;
    top: 1px;
    left: 2px;
    width: 18px;
    height: 18px;
    transition: all 0.2s ease-in-out;
    &::before {
      position: absolute;
      top: -7px;
      right: 1px;
      bottom: 5px;
      left: -2px;
      background-color: @inputTextFieldBorderColor;
      border-radius: 9px;
      box-shadow: none;
      transition: all 0.2s ease-in-out;
      content: '';
    }
  }
  &:focus {
    box-shadow: none;
  }
  &:hover:not(.ant-switch-disabled) {
    background-color: @white;
  }

  &.ant-switch-checked {
    border: 1px solid @primaryButtonColor;
    background: @white;
    &:hover:not(.ant-switch-disabled) {
      background-color: @white;
    }
    .ant-switch-handle {
      inset-inline-start: calc(100% - 18px);
      left: calc(100% - 18px);
      &::before {
        position: absolute;
        top: -7px;
        right: 0;
        bottom: 5px;
        left: 0;
        background-color: @primaryButtonColor;
        border-radius: 9px;
        box-shadow: none;
        transition: all 0.2s ease-in-out;
        content: '';
      }
    }
  }
}

.ant-switch-checked:focus {
  box-shadow: none;
}

//anchor tag styles
a {
  color: @primaryColor;
  &:hover {
    color: @primaryColor;
  }
}

//Pagination Styles
.ant-pagination {
  .ant-pagination-item {
    border: 1px solid @borderGrey;
    &:not(.ant-pagination-item-active):hover {
      border-color: @primaryColor;
      background: @white;
      a {
        color: @primaryColor;
      }
    }
    &.ant-pagination-item-active {
      border-color: @primaryColor;
      font-weight: 500;
      a {
        color: @primaryColor;
      }
    }
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    .ant-pagination-item-link {
      background-color: #fff;
      border: 1px solid #d9d9d9;
      border-radius: 6px;
    }
  }
  .ant-pagination-jump-next,
  .ant-pagination-jump-prev {
    .ant-pagination-item-container .ant-pagination-item-link-icon {
      color: @primaryColor;
    }
  }
  .ant-pagination-options {
    .ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(
        .ant-pagination-size-changer
      ) {
      &.ant-select-focused {
        .ant-select-selector {
          border: 1px solid @primaryColor;
          box-shadow: none;
        }
      }
      &:hover {
        .ant-select-selector {
          border: 1px solid @primaryColor;
        }
      }
    }
  }
}

//date Picker
.ant-picker-dropdown {
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid @cboPrimaryColor;
  }
  .ant-picker-cell-in-view.ant-picker-cell-selected:not(.ant-picker-cell-disabled)
    .ant-picker-cell-inner {
    background-color: @cboPrimaryColor;
    color: @white;
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-disabled)
    .ant-picker-cell-inner {
    background: @cboPrimaryColor;
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-disabled)
    .ant-picker-cell-inner {
    background: @cboPrimaryColor;
  }

  .ant-picker-header {
    .ant-picker-header-view {
      .ant-picker-decade-btn,
      .ant-picker-year-btn,
      .ant-picker-month-btn {
        &:hover {
          color: @cboPrimaryColor;
        }
      }
    }
  }
}

//Drawer footer
.ant-steps {
  width: 40rem;

  .ant-steps-item-process .ant-steps-item-icon {
    background: @primaryButtonColor;
    border: none;
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: @primaryButtonColor;
    border: none;
    .ant-steps-icon {
      color: @white;
    }
  }
  .ant-steps-item-wait .ant-steps-item-icon {
    background: @white;
    border-color: @disabled-text;
    color: @disabled-text;
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: @primaryButtonColor;
  }
  &-item-container {
    width: 100%;
  }
}

//form - select multiple styles
.ant-select-item-option-selected:not(.ant-select-item-option-disabled)
  .ant-select-item-option-state {
  display: none;
}

//form - form input
.ant-form-item {
  .ant-input-outlined {
    border: 1px solid @dividerColor;
    &:not(.ant-input-disabled) {
      &:hover {
        border: 1px solid @borderGrey;
      }
      &:focus,
      &:focus-within {
        border: 1px solid @borderGrey;
        box-shadow: 0px 1px 4px @shadow1;
      }
    }
  }
}

//form - Drag/Browse and Upload file
.ant-upload-wrapper .ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: @borderGrey;
}

//checkbox

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: @cboPrimaryColor;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner {
  background: @cboPrimaryColor;
}
