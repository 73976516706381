@import '../../../theme/theme.less';
.trunorth-dashboard-wrapper{
    // padding: 2rem;
    &.service-unreachable{
        
    display: flex;
    justify-content: center;
    align-items: center;
    background: @white;
    padding-bottom: 10rem;
    flex-direction: column;
    }
    .service-unreachable-image{
        height: 40rem;
    }
    .assess-no-data{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: @white;
        padding: 2rem;
        height: 50vh;
    }
    .assess-iframe{
        height: 100vh;
        width: 100%;
    }
    .assess-authorizing{
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
