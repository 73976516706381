@import '../../theme/theme.less';
.open-asset{
  background: none !important;
  color: @white !important;
  border: none !important;
  font-size: 1.25rem !important;
}

.reload-asset{
  font-size: .75rem;
  margin-right: 1rem;
  a{
    text-decoration: underline;
    color: @white;
    font-size: .9rem;
  }
}